<template>
	<PaddingContainer
		id="imoveis"
		:padding="padding"
		component="section"
		class="listing-block"
		:class="{ 'default-listing-padding': !padding }"
	>
		<h2 v-if="title" class="listing-heading">
			{{ title }}
		</h2>
		<div v-if="variant === 'default'" class="cards-section">
			<div v-if="properties.length" class="card-grid">
				<PropertyCard
					v-for="property in properties"
					:key="property.id"
					:variant="cardVariant"
					:property="property"
					:tag-text-color="tagTextColor"
					:tag-background-color="tagBackgroundColor"
					@open-campaign-modal="
						$emit('open-campaign-modal', property.commercialId)
					"
				/>
			</div>
			<ClientOnly>
				<ErrorMessage
					v-if="shouldShowErrorMessage"
					title="Nenhum imóvel encontrado"
					:description="errorMessage"
				/>
				<div
					v-if="includeSkeletonLoading"
					v-show="shouldShowLoading"
					class="card-grid"
				>
					<div
						v-for="(_, index) in perPage"
						:key="`skeleton-card:${index}`"
						class="animate-skeleton skeleton-card"
					></div>
				</div>
				<LoadingIcon
					v-else-if="!includeSkeletonLoading"
					v-show="shouldShowLoading"
					class="loading"
				/>
			</ClientOnly>

			<div
				v-if="shouldShowMorePropertiesButton"
				class="see-more-properties-section"
			>
				<div v-if="seeMorePropertiesText" class="see-more-properties-text">
					{{ seeMorePropertiesText }}
				</div>

				<ButtonBlock
					class="show-more-properties-button"
					:text="showMorePropertiesButtonLabel"
					type="button"
					size="medium"
					is-outlined
					@click.prevent="enableInfiniteScroll"
				/>
			</div>

			<div
				v-show="isInfiniteScrollEnabled"
				ref="scrollSentinel"
				class="scroll-sentinel"
			></div>
		</div>
	</PaddingContainer>
</template>

<script setup lang="ts">
import {
	useIntersectionObserver,
	watchDebounced,
	whenever
} from '@vueuse/core';

import type { WebsiteStyles } from '@SHARED/core/entities/WebsiteConfig';
import type { PropertyListingSection } from '@SHARED/core/entities/sections/PropertyListingSection';
import type { Property } from '@SHARED/core/entities/Property';
import type { PropertyFilters } from '@SHARED/core/entities/Property/filters';

import { PropertiesFiltersPresenter } from '@SHARED/presenters/PropertiesFiltersPresenter';
import { isEmpty } from '@SHARED/utils';
import { getCSSColorVar } from '@SHARED/utils/style';
import { scrollToPageTop } from '@SHARED/utils/scroll';
import {
	COLOR_SERVICE,
	PROPERTIES_REPOSITORY
} from '@SHARED/utils/vueProvidersSymbols';

import PaddingContainer from '@SHARED/components/molecules/PaddingContainer.vue';
import ButtonBlock from '@SHARED/components/blocks/ButtonBlock.vue';
import ErrorMessage from '@SHARED/components/molecules/ErrorMessage.vue';
import PropertyCard from '@SHARED/components/molecules/PropertyCard.vue';

import LoadingIcon from '~icons/mdi/loading';

defineOptions({ name: 'PropertyListingSection' });

const colorService = inject(COLOR_SERVICE)!;

type PropertyListingSectionProps = PropertyListingSection['config'] & {
	isLoading?: boolean;
	properties?: Property[] | null;
	propertyFilters?: Partial<PropertyFilters>;
	isInfiniteScrollEnabledByDefault?: boolean;
	identifier?: string;
};

const props = withDefaults(defineProps<PropertyListingSectionProps>(), {
	variant: 'default',
	cardVariant: 'default',
	perPage: 9,
	includeSkeletonLoading: true,
	isLoading: false,
	properties: null,
	isInfiniteScrollEnabledByDefault: false,
	propertyFilters: () => ({}),
	identifier: ''
});

type Emits = {
	(e: 'open-campaign-modal', commercialId: string): void;
	(e: 'start-properties-filtering'): void;
	(e: 'finish-properties-filtering'): void;
	(e: 'update-total-filtered-properties-count', count: number | null): void;
};

const emit = defineEmits<Emits>();

const styles = useState<WebsiteStyles>('styles');

const domain = useState<string>('domain');

const propertiesRepository = inject(PROPERTIES_REPOSITORY)!;

const page = ref<number>(1);

const isScrollSentinelVisible = ref<boolean>(false);

const isInfiniteScrollEnabled = ref<boolean>(
	props.isInfiniteScrollEnabledByDefault
);

/**
 *
 * @param variableName Nome da variável que será utilizada para criar a key do useState
 * @returns A key que deve ser utilizado no useState
 * @description Essa função gera uma key (quase) única para ser usada nas chamadas do useState, com base nas props do componente.
 *
 * Essa estratégia foi pensada para previnir que todas as intâncias do componente PropertyListingSection usem sempre o mesmo useState, visto que
 * este composable cria um contexto global.
 *
 * Essa implementação com o useState foi necessária pois utilizando um ref normal obtinhamos
 * alguns erros de hydration mismatch, pois os dados da ref não eram sincronizados no servidor e no cliente
 */
function getUseStateKeyFor(variableName: string): string {
	const identifier = props.identifier || 'no-identifier-defined';

	const { identifier: _, ...componentProps } = props;

	const propsJson = JSON.stringify(componentProps);

	return `PropertyListingSection::${domain.value}:${variableName}:${identifier}:${propsJson}`;
}

const totalPropertyCount = useState<number | null>(
	getUseStateKeyFor('totalPropertyCount'),
	() => null
);
const totalFilteredProperties = useState<number | null>(
	getUseStateKeyFor('totalFilteredProperties'),
	() => null
);

onUnmounted(() => {
	totalPropertyCount.value = null;
	totalFilteredProperties.value = null;
});

watchEffect(() => {
	emit('update-total-filtered-properties-count', totalFilteredProperties.value);
});

const hasAppliedNewFilters = ref<boolean>(false);

const hasMorePropertiesToShow = computed<boolean>(() => {
	if (totalPropertyCount.value === null) return true;

	return properties.value.length < (totalFilteredProperties.value || 0);
});

const propertiesOffset = computed<number>(
	() => props.perPage * (page.value - 1)
);

const scrollSentinel = ref<HTMLElement | null>(null);

const tagBackgroundColor = computed<string>(() =>
	getCSSColorVar(
		props?.cardTagBackgroundColor || styles.value.appearance.background
	)
);

const tagTextColor = computed<string>(() =>
	getCSSColorVar(props?.cardTagTextColor || styles.value.appearance.text)
);

const skeletonPrimaryColor = colorService.transparentizeColor(
	styles.value.colorPalette.primary,
	0.96
);
const skeletonSecondaryColor = colorService.transparentizeColor(
	styles.value.colorPalette.primary,
	0.92
);

const shouldShowBackdropLoading = computed<boolean>(
	() => properties.value.length !== 0
);

const shouldShowLoading = computed<boolean>(() => {
	const isFetching = isFetchingProperties.value || props.isLoading;
	const isBackdropInactive =
		!shouldShowBackdropLoading.value || !hasAppliedNewFilters.value;

	return isFetching && isBackdropInactive;
});

const fetchPropertiesAsyncDataKey = `${domain.value}:properties-section:${JSON.stringify(props.propertyFilters)}`;

const {
	data: properties,
	pending: isFetchingProperties,
	execute: fetchPropertiesAsyncData
} = useAsyncData<Property[]>(
	fetchPropertiesAsyncDataKey,
	async () => {
		if (props.properties) return props.properties;

		const fetchedData = await fetchProperties();

		return fetchedData.properties;
	},
	{ default: () => [] }
);

const seeMorePropertiesText = computed<string | null>(() => {
	const regions = props.propertyFilters?.regions
		?.map(region => region.trim())
		.filter(Boolean);

	const propertyTypes = props.propertyFilters?.propertyTypes
		?.map(PropertiesFiltersPresenter.getPropertyTypePlural)
		.filter(Boolean);

	if (!regions?.length || !propertyTypes?.length) return null;

	const transactionType =
		props.propertyFilters?.transactionType || 'rentOrSell';

	const transactionTypeText =
		PropertiesFiltersPresenter.appliedTransactionTypeTextByValue[
			transactionType
		];

	const firstRegions = regions.slice(0, -1);
	const lastRegion = regions[regions.length - 1];

	const regionsText =
		regions.length === 1
			? lastRegion
			: `${firstRegions.join(', ')} e ${lastRegion}`;

	const firstPropertyTypes = propertyTypes.slice(0, -1);
	const lastPropertyType = propertyTypes[propertyTypes.length - 1];

	const propertyTypesText =
		propertyTypes.length === 1
			? lastPropertyType
			: `${firstPropertyTypes.join(', ')} e ${lastPropertyType}`;

	return `Ver mais ${propertyTypesText} para ${transactionTypeText} em ${regionsText}`;
});

const shouldShowMorePropertiesButton = computed<boolean>(
	() =>
		!isInfiniteScrollEnabled.value &&
		hasMorePropertiesToShow.value &&
		!shouldShowLoading.value
);

const showMorePropertiesButtonLabel = computed<string>(() => {
	if (totalFilteredProperties.value === null) return 'Ver mais imóveis';

	const remainingPropertiesCount =
		totalFilteredProperties.value - properties.value.length;

	return remainingPropertiesCount
		? `Ver mais ${remainingPropertiesCount} imóveis`
		: 'Ver mais imóveis';
});

const shouldShowErrorMessage = computed<boolean>(
	() =>
		!properties.value.length &&
		!shouldShowLoading.value &&
		totalPropertyCount.value !== null
);

const errorMessage = ref<string | null>(null);

async function fetchProperties(): Promise<{
	properties: Property[];
	totalCount: number;
	filteredCount: number;
}> {
	if (!hasMorePropertiesToShow.value && !hasAppliedNewFilters.value) {
		resetFiltersPropertyCount();

		return {
			properties: properties.value,
			totalCount: totalPropertyCount.value || 0,
			filteredCount: 0
		};
	}

	const [propertiesResult, propertiesRequestError] =
		await propertiesRepository.getPropertiesSharedAtCompanyWebsite({
			domain: domain.value,
			maxQuantity: props.perPage,
			filters: props.propertyFilters,
			offset: propertiesOffset.value
		});

	if (propertiesRequestError || !propertiesResult) {
		const notFoundError = {
			response: { status: 404 }
		};

		const error =
			(propertiesRequestError?.originalErrorObject as any) || notFoundError;

		errorMessage.value =
			error?.response.status === 404
				? 'No momento, não temos nenhum imóvel disponível.'
				: 'Ocorreu um erro ao carregar os imóveis.';

		resetFiltersPropertyCount();

		return {
			properties: [],
			totalCount: 0,
			filteredCount: 0
		};
	}

	totalPropertyCount.value = propertiesResult.totalCount;
	totalFilteredProperties.value = propertiesResult.filteredCount;
	hasAppliedNewFilters.value = false;

	emit('finish-properties-filtering');

	if (!isEmpty(props.propertyFilters) && propertiesResult.filteredCount === 0) {
		errorMessage.value =
			'Nenhum imóvel encontrado com os filtros selecionados.';
	}

	return propertiesResult;
}

function enableInfiniteScroll() {
	isInfiniteScrollEnabled.value = true;
}

function resetFiltersPropertyCount() {
	totalPropertyCount.value = 0;
	totalFilteredProperties.value = 0;
	hasAppliedNewFilters.value = false;
}

const {
	pause: pauseInfiniteScroll,
	resume: resumeInfiniteScroll,
	isActive: isInfiniteScrollActive
} = useIntersectionObserver(
	scrollSentinel,
	([{ isIntersecting }]) => {
		if (!isInfiniteScrollEnabled.value || !hasMorePropertiesToShow.value) {
			return;
		}

		isScrollSentinelVisible.value = isIntersecting;
	},
	{ threshold: 1 }
);

watch(isScrollSentinelVisible, newValue => {
	if (
		!newValue ||
		!hasMorePropertiesToShow.value ||
		isFetchingProperties.value
	) {
		return;
	}

	page.value++;
});

whenever(page, async () => {
	if (page.value === 1) return scrollToPageTop('smooth');

	try {
		isFetchingProperties.value = true;

		const { properties: fetchedProperties } = await fetchProperties();

		properties.value = properties.value.concat(fetchedProperties);
	} catch (e) {
		// <!-- TODO: melhorar tratamento de erro -->
		// eslint-disable-next-line no-console
		console.error(e);
	} finally {
		isFetchingProperties.value = false;

		const shouldLoadNextPage =
			isScrollSentinelVisible.value &&
			hasMorePropertiesToShow.value &&
			isInfiniteScrollActive.value;

		if (shouldLoadNextPage) page.value++;
	}
});

watchDebounced(
	() => props.propertyFilters,
	async () => {
		page.value = 1;

		hasAppliedNewFilters.value = true;

		if (shouldShowBackdropLoading.value) emit('start-properties-filtering');

		await fetchPropertiesAsyncData();
	},
	{ debounce: 500 }
);

watch(hasAppliedNewFilters, newValue => {
	if (newValue && isInfiniteScrollActive.value) return pauseInfiniteScroll();

	resumeInfiniteScroll();
});
</script>

<style lang="scss" scoped>
.listing-block {
	background-color: var(--white);
	display: flex;
	flex-direction: column;

	&.default-listing-padding {
		padding-top: 1.5rem;
		padding-bottom: 3rem;
	}

	.listing-heading {
		font-size: 1.875rem;
		width: 100%;
		text-align: center;
		font-family: var(--heading-font);

		margin-bottom: 1.5rem;

		@include screen-up(md) {
			font-size: 2.25rem;
		}
	}

	.cards-section {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 3rem;
		position: relative;

		.card-grid {
			display: grid;
			grid-template-columns: repeat(1, minmax(0, 1fr));
			gap: 1.5rem;

			@include screen-up(md) {
				grid-template-columns: repeat(2, minmax(0, 1fr));
			}

			@include screen-up(xl) {
				grid-template-columns: repeat(3, minmax(0, 1fr));
			}
		}
	}
}

.see-more-properties-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1.25rem;

	.see-more-properties-text {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;

		color: var(--black);
		font-family: var(--default-font);
		font-size: 1.25rem;
		font-weight: 500;
		line-height: 150%;
	}

	.show-more-properties-button {
		align-self: center;
	}
}

.loading {
	align-self: center;
	width: 3.5rem;
	height: 3.5rem;
}

.skeleton-card {
	width: 100%;
	min-height: 300px;
	background: linear-gradient(
			90deg,
			v-bind(skeletonPrimaryColor),
			v-bind(skeletonSecondaryColor),
			v-bind(skeletonPrimaryColor)
		)
		0 0 / 200% 100%;

	background-size: 200% 100%;
}
</style>
